import React, { useState, useEffect } from 'react';
import PTGLogo from 'assets/images/logos/ptg-white.png';
import Menu from 'components/ui/menu';
import ActionButton from 'components/ui/buttons/action-button';
import { useTranslation } from 'react-i18next';
import { IoMdMenu } from 'react-icons/io';
import { useAppDispatch } from 'reduxStore/hooks';
import { setDisplayModalPM } from 'reduxStore/slices/contact.slice';
import { Link } from 'react-router-dom';
import Animated from 'components/generals/AnimatedComponent';
import { setDisplayMobileMenu } from 'reduxStore/slices/contact.slice';
import { useLocation } from 'react-router-dom';
import LanguangeSelector from 'components/generals/language-selector';
import config from 'config';
export default function MainHeader() {
    const dispatch = useAppDispatch();
    const [scrolled, setScrolled] = useState(false);
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 0) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const menuClasses = [''];
    if (scrolled) {
        menuClasses.push('scrolled transition');
    }

    return (
        <>
            <header className={`nv__header ${menuClasses.join(' ')}`}>
                <section className="nv__header__container">
                    <Animated animation={'animate__fadeInDown logo-absolute'}>
                        <div className="nv__header__logos">
                            {pathname !== '/' && (
                                <>
                                    <div className="nv__header__logo">
                                        <Link to="/#home">
                                            <img
                                                src={`${config.SITE_URL}/assets/logos/panamerican-white-text.png`}
                                                alt="Logo Panamerican"
                                            />
                                        </Link>
                                    </div>
                                </>
                            )}
                            {pathname === '/ptg' && (
                                <>
                                    <div className="nv__header__logo__ptg ">
                                        <img src={PTGLogo} alt="Logo PTG " />
                                    </div>
                                </>
                            )}
                        </div>
                    </Animated>

                    <Animated animation={'animate__fadeInDown header-menu'}>
                        <Menu />
                    </Animated>
                    <Animated animation={'animate__fadeInDown'}>
                        <section className="nv__header__button__section">
                            <div
                                className="nv__header__menu nv__header__menu--mobile"
                                onClick={() => {
                                    dispatch(setDisplayMobileMenu(true));
                                }}
                            >
                                <IoMdMenu />
                            </div>

                            <ActionButton
                                text={t('menu.contact')}
                                type="rounded"
                                onClick={() => {
                                    dispatch(setDisplayModalPM(true));
                                }}
                            />

                            <LanguangeSelector />
                        </section>
                    </Animated>
                </section>
            </header>
        </>
    );
}
