import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
const menuItems = [
    {
        name: 'menu.home',
        url: '/',
    },
    {
        name: 'menu.services',
        url: '/services',
    },

    {
        name: 'menu.about',
        url: '/about',
    },
    {
        name: 'menu.politics',
        url: '/policy',
    },
];

export default function Menu() {
    const { t } = useTranslation();

    return (
        <>
            <section>
                <ul className="nv__header__menu  nv__header__menu--web">
                    {menuItems.map((item) => {
                        return (
                            <li key={item.name}>
                                <Link to={item.url}> {t(item.name)}</Link>
                            </li>
                        );
                    })}
                </ul>
            </section>
        </>
    );
}
