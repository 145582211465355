import React, { useRef, useEffect, MutableRefObject } from 'react';
import { useAppSelector } from 'reduxStore/hooks';
import config from 'config';
export default function VideoComponent() {
    const displayModal = useAppSelector((state) => state.componentSlice.displayModal);
    const videoSize = useAppSelector((state) => state.componentSlice.videoSize);
    const vidRef: MutableRefObject<HTMLVideoElement | null> = useRef(null);

    useEffect(() => {
        if (vidRef.current) {
            // vidRef.current.play();
            const timer = setTimeout(() => {
                if (vidRef.current) {
                    vidRef.current.muted = false;
                }
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, []);
    return (
        <>
            {displayModal && (
                <div className={`nv__video ${videoSize}`}>
                    <video
                        src={`${config.SITE_URL}/assets/videos/videoPanamerican.mp4`}
                        controls
                        width="100%"
                        height="100%"
                        ref={vidRef}
                    />
                </div>
            )}
        </>
    );
}
