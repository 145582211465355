import React from 'react';
import narvalLogo from 'assets/images/logos/logo-white-blue.png';
import { NVInput } from 'components/generals/inputs/nv__input';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { contactFormSchema } from 'schemas/contact.form.schema';
import { ContactFormInterface } from 'interfaces/contact.form.interface';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import ContactInfo from 'components/generals/forms/contact/contactInfo';
import config from 'config';
export default function ContactForm() {
    const { t } = useTranslation();
    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<ContactFormInterface>({
        resolver: yupResolver(contactFormSchema),
        shouldFocusError: false,
    });

    const onSubmit: SubmitHandler<ContactFormInterface> = async (data) => {
        console.log(data);
        reset();
        message.success('Mensaje enviado correctamente', 3);
    };

    return (
        <main className="nv__contact__form">
            <ContactInfo />
            <div className="nv__contact__form__block">
                <div className="contact__form__base">
                    <div className="contact__form__base__container">
                        <img
                            className="contact__form__base__logo"
                            src={`${config.SITE_URL}/assets/logos/logo-white-blue.png`}
                            alt="Narval Logo"
                        />

                        <div className="contact__form__fields">
                            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                                <NVInput
                                    name="name"
                                    type="text"
                                    placeholder={t('contactForm.form.name')}
                                    control={control}
                                    className=""
                                    errorMessage={errors.name?.message}
                                />

                                <NVInput
                                    name="company"
                                    type="text"
                                    placeholder={t('contactForm.form.company')}
                                    control={control}
                                    className=""
                                    errorMessage={errors.company?.message}
                                />

                                <NVInput
                                    name="email"
                                    type="text"
                                    placeholder={t('contactForm.form.email')}
                                    control={control}
                                    className=""
                                    errorMessage={errors.email?.message}
                                />

                                <NVInput
                                    name="message"
                                    type="text"
                                    placeholder={t('contactForm.form.message')}
                                    control={control}
                                    className=""
                                    errorMessage={errors.message?.message}
                                />

                                <input type="submit" value={t('contactForm.form.submit')} />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
